<template>
    <div v-if="modal">
        <v-dialog v-model="modal"
                  width="80%">
            <template>
                <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn> -->

            </template>
            <v-card>
                <v-card-title>
                    <span class="text-h5 text-color-black">Terms & Conditions</span>
                    <!-- <span style="position: absolute; right: 10px; top: 17px;cursor: pointer;"
                          @click="dialogOpen">
                        x
                    </span> -->
                    <span @click="dialogOpen"
                          style="position: absolute; right: 10px;cursor: pointer;">
                        <v-icon>
                            {{ icons.mdiClose }}
                        </v-icon>
                    </span>
                    <!-- <br> -->
                    <!-- <span class="text-h5 text-color-black mt-8"
                          style="font-size: 18px !important;margin-bottom: -9px;">Please read the below
                        Terms and Conditions and accept by checking the box in order to continue with your
                        enrollment.</span> -->
                </v-card-title>
                <v-card-text style="overflow: auto;height: 70vh;padding: 30px;">

                    <div style="font-size: 16px;">
                        <div style="text-align: justify; padding: 20px;">
                            <span>
                                End user license agreement <br> <br>

                                Between <br> <br>

                                American Express Saudi Arabia <br> <br>

                                And you, the account holder. <br> <br>

                                Please read the terms of this agreement carefully before downloading or using the American
                                Express Saudi
                                Arabia Smart phone app, because by downloading or using the American Express Saudi Arabia
                                Smart phone app
                                you will have indicated your acknowledgement and agreement to each term, condition, and
                                disclaimer herein.
                                <br> <br>

                                Our Terms of Service (aka our “Website Rules and Regulations”) and our Privacy Statement
                                (each of which is
                                accessible via the links provided in the American Express Saudi Arabia Smart Phone App) also
                                govern your use
                                of the American Express Saudi Arabia Smart Phone App, are incorporated herein by reference
                                and together with
                                this Agreement are hereafter collectively referred to as the “American Express Saudi Arabia
                                Service
                                Agreements.” <br> <br>

                                American Express Saudi Arabia Company, its subsidiaries and affiliates (“Our”, “We”, “Us”,
                                “American Express
                                Saudi Arabia”, “Provider”) will license the American Express Saudi Arabia Smart Phone App to
                                you only upon
                                all of the terms and conditions contained in the American Express Saudi Arabia Service
                                Agreements. If you do
                                not agree to the terms set forth in the American Express Saudi Arabia service agreements,
                                you should not
                                download or use the American Express Saudi Arabia Smart phone app. This American Express
                                Saudi Arabia Smart
                                phone app is licensed, not sold, to you for your personnal use only under the terms of the
                                American Express
                                Saudi Arabia services agreements. American Express Saudi Arabia reserves all rights not
                                expressly granted to
                                you hereunder. <br> <br>
                            </span>
                        </div>
                        <ol style="margin-top: 15px; text-align: justify; padding: 20px;">
                            <li>
                                Scope of License: This license granted to you for the American Express Saudi Arabia Smart
                                Phone App by
                                American Express Saudi Arabia is limited to a non-exclusive and non-transferable license to
                                use the American
                                Express Saudi Arabia Smart Phone App on a Smart Phone, that you own or legitimately control
                                and as permitted
                                by the Usage Rules under the terms and conditions of the American Express Saudi Arabia
                                Service Agreements
                                (the “License”). For the purpose of this Agreement the expression “Smart Phone” refers
                                specifically to a
                                mobile phone with various operating systems which includes functions similar to those found
                                on personal
                                computers, and provides a one-stop solution for information management, mobile calls, email
                                sending, and
                                Internet access which is able to run the American Express Saudi Arabia Smart Phone App. This
                                license does
                                not allow you to use the American Express Saudi Arabia Smart Phone App available over a
                                network where it
                                could be used by multiple devices at the same time. <br> <br>
                                You may not rent, lease, lend, sell, redistribute or sublicense the American Express Saudi
                                Arabia Smart
                                Phone App. You may not copy (except as expressly permitted by this license and the Usage
                                Rules and the
                                American Express Saudi Arabia Services Agreement), decompile, reverse engineer, disassemble,
                                attempt to
                                derive the source code of, modify, or create derivative works of the American Express Saudi
                                Arabia Smart
                                Phone App, any updates, or any part thereof (except as and only to the extent any foregoing
                                restriction is
                                prohibited by applicable law or to the extent as may be permitted by the licensing terms
                                governing use of
                                any open sourced components included with the American Express Saudi Arabia Smart Phone
                                App). Any attempt to
                                do so is a violation of the rights of American Express Saudi Arabia and its licensors. If
                                you breach this
                                restriction, you may be subject to prosecution and damages. The terms of the license will
                                govern any
                                upgrades provided by American Express Saudi Arabia that replace and/or supplement the
                                American Express Saudi
                                Arabia Smart Phone App, unless such upgrade is accompanied by a separate license in which
                                case the terms of
                                that license will govern.
                            </li> <br>
                            <li>
                                Changes to this Agreement. American Express Saudi Arabia reserves the right to revise and
                                amend this
                                Agreement at any time, at its sole discretion and without prior notice. Upon modification,
                                American Express
                                Saudi Arabia will update the date indicated after "Last Modified" at the beginning of this
                                document and note
                                this Agreement has been modified in the “Legal” tab section of the American Express Saudi
                                Arabia Smart Phone
                                App which is also a formal invitation to you to carefully read and agree thereon. Any
                                modifications to this
                                Agreement will only affect your and our respective rights and obligations from the “last
                                modified date” and
                                thereafter. If you do not agree with the modified terms, however, you must immediately then
                                cease using the
                                American Express Saudi Arabia Smart Phone App. Your continued use of the American Express
                                Saudi Arabia Smart
                                Phone App constitutes your formal continued agreement to the terms and conditions herein.
                            </li> <br>
                            <li>
                                Consent to Use of Data: You agree that American Express Saudi Arabia shall at all times
                                enjoy the right, but
                                not the obligation, to collect, monitor and use data and related information on your Smart
                                Phone, including
                                but not limited to technical information about your Smart Phone, operating system and
                                various applications
                                software, and peripherals, that is gathered periodically through features of the American
                                Express Saudi
                                Arabia Smart Phone App that may control and/or monitor your Smart Phone running or
                                interacting with the
                                American Express Saudi Arabia Smart Phone App to facilitate the provision of software
                                updates, product
                                support and other services to you (if any) related to the American Express Saudi Arabia
                                Smart Phone App.
                                American Express Saudi Arabia and/or Third Party Beneficiaries as herein defined, may use
                                this data, to
                                improve its products or to provide services or technologies to you. American Express Saudi
                                Arabia’s
                                collection and use of personal data is governed by the current American Express Saudi Arabia
                                Privacy
                                Statement and its amendments (which is accessible via the link in the American Express Saudi
                                Arabia Smart
                                Phone App) which by this reference constitutes integral part hereof.
                            </li> <br>
                            <li>
                                Termination. The License granted hereunder is effective until either terminated by you or
                                American Express
                                Saudi Arabia. Your rights granted hereunder will terminate automatically without notice from
                                American
                                Express Saudi Arabia if you fail, at American Express Saudi Arabia’s free determination, to
                                comply with any
                                term of American Express Saudi Arabia Service Agreements. Upon termination of the License,
                                you must
                                immediately cease all use of the American Express Saudi Arabia Smart Phone App, and destroy
                                all copies, full
                                or partial, of the American Express Saudi Arabia Smart Phone App.
                            </li> <br>
                            <li>
                                Services; Objectionable Materials. The American Express Saudi Arabia Smart Phone App may
                                enable access to
                                American Express Saudi Arabia’ and third party services and web sites (collectively and
                                individually,
                                "Services"). Use of the Services may require Internet access and that you accept additional
                                terms of
                                service. You understand that by using any of the Services, you may encounter content that
                                may be deemed
                                offensive, indecent, or objectionable, which content may or may not be identified as having
                                explicit
                                language, and that the results of any search or entering of a particular URL may
                                automatically and
                                unintentionally generate links or references to objectionable material. Nevertheless, you
                                agree to use the
                                Services at your sole risk and that American Express Saudi Arabia shall not have any
                                liability to you for
                                content that may be found to be offensive, indecent, or objectionable. American Express
                                Saudi Arabia and its
                                suppliers do not warrant that the Services or any part thereof will continue to be
                                available, or will
                                operate without interruption or modification.
                            </li> <br>
                            <li>
                                Third Party Materials. Certain Services may display, include or make available content,
                                data, information,
                                applications or materials from third parties (“Third Party Materials”) or provide links to
                                certain third
                                party web sites. By using the Services, you acknowledge and agree that American Express
                                Saudi Arabia is not
                                responsible for examining or evaluating the content, accuracy, completeness, timeliness,
                                validity, copyright
                                compliance, legality, decency, quality or any other aspect of such Third Party Materials or
                                web sites.
                                American Express Saudi Arabia does not warrant or endorse and does not assume and will not
                                have any
                                liability or responsibility to you or any other person for any third party services, Third
                                Party Materials
                                or web sites, or for any other materials, products, or services of third parties. Third
                                Party Materials and
                                links to other web sites are provided solely as a convenience to you.
                            </li> <br>
                            <li>
                                Advice Disclaimer. Financial information displayed by any Services is for general
                                informational purposes
                                only and is not intended to be relied upon as investment advice. Before executing any
                                securities transaction
                                based upon information obtained through the Services, you should consult with a financial
                                professional.
                                Nothing in any Service constitutes investment advice, including American Express Saudi
                                Arabia' filings with
                                the Securities and Exchange Commission. American Express Saudi Arabia provides investor
                                relations materials
                                for convenience and information only. In addition, investor relations materials and other
                                content are not
                                offers to sell or solicitation of an offer to buy any security. Location data provided by
                                any Services is
                                for basic navigational purposes only and is not intended to be relied upon in situations
                                where precise
                                location information is needed or where erroneous, inaccurate or incomplete location data
                                may lead to death,
                                personal injury, property or environmental damage. Neither American Express Saudi Arabia,
                                nor any of its
                                content providers, guarantees the availability, accuracy, completeness, reliability, or
                                timeliness of stock
                                information or location data displayed by any Services.
                            </li> <br>
                            <li>
                                Proprietary Materials. You agree that American Express Saudi Arabia Services contain
                                proprietary content,
                                information and material that is protected by applicable intellectual property and other
                                laws, including but
                                not limited to copyright, and that you will not use such proprietary content, information or
                                materials in
                                any way whatsoever except for permitted use of the Services. All title and copyright in and
                                to the American
                                Express Saudi Arabia Smart Phone App (including but not limited to any images, photographs,
                                animation,
                                video, audio, music, text and applets, if any, incorporated into the American Express Saudi
                                Arabia Smart
                                Phone App are owned by American Express Saudi Arabia, it trademark licensors, suppliers
                                and/or third
                                parties. The trademarks, logos and service marks ("Marks") displayed on the American Express
                                Saudi Arabia
                                Smart Phone App and the American Express Saudi Arabia Services are the property of American
                                Express Saudi
                                Arabia, its trademark licensors and other parties. You are prohibited from using any Marks
                                for any purpose
                                including, but not limited to use as metatags on other pages or sites on the World Wide Web
                                without the
                                written permission of American Express Saudi Arabia, or such third party which may own the
                                Marks, which is
                                free to withhold. No portion of the American Express Saudi Arabia Services may be reproduced
                                in any form or
                                by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create
                                derivative works based
                                on the American Express Saudi Arabia Services, in any manner, and you may not exploit the
                                American Express
                                Saudi Arabia Services in any unauthorized way whatsoever, including but not limited to, by
                                trespass or
                                burdening network capacity. You further agree not to use the Services in any manner to
                                harass, abuse, stalk,
                                threaten, defame or otherwise infringe or violate the rights of any other party, and that
                                American Express
                                Saudi Arabia is not in any way responsible for any such use by you, nor for any harassing,
                                threatening,
                                defamatory, offensive or illegal messages or transmissions that you may receive as a result
                                of using any of
                                the Services.
                            </li> <br>
                            <li>
                                Languages, Local Rules. In addition, third party services and Third Party Materials that may
                                be accessed
                                from, displayed on or linked to from the Smart Phone are not available in all languages or
                                in all countries.
                                American Express Saudi Arabia makes no representation that such Services and materials are
                                appropriate or
                                available for use in any particular location. To the extent you choose to access such
                                Services or materials,
                                you do so at your own initiative and are responsible for compliance with any applicable
                                laws, including but
                                not limited to applicable local laws.
                            </li> <br>
                            <li>
                                Modification, Suspension and Discontinuation.  American Express Saudi Arabia, and its
                                licensors, reserve the
                                right to change, suspend, remove, disable or permanently discontinue access to or the
                                appearance of the
                                American Express Saudi Arabia Smart Phone App and any American Express Saudi Arabia provided
                                Services at any
                                time without notice. In no event will American Express Saudi Arabia be liable for the
                                removal of or
                                disabling of access to any such Services. American Express Saudi Arabia may also impose
                                limits on the use of
                                or access to certain Services, in any case and without notice or liability.
                            </li> <br>
                            <li>
                                No warranty: you expressly acknowledge and agree that use of the American Express Saudi
                                Arabia Smart phone
                                app is at your sole risk and that the entire risk as to satisfactory quality, performance,
                                accuracy and
                                effort is with you. To the maximum extent permitted by applicable law, the American Express
                                Saudi Arabia
                                Smart phone app and any services performed or provided by the American Express Saudi Arabia
                                Smart phone app
                                are provided "as is" and “as available”, with all faults and without warranty of any kind,
                                and American
                                Express Saudi Arabia hereby disclaims all warranties and conditions with respect to the
                                American Express
                                Saudi Arabia Smart phone app and any services, either express, implied or statutory,
                                including, but not
                                limited to, the implied warranties and/or conditions of merchantability, of satisfactory
                                quality, of fitness
                                for a particular purpose, of accuracy, of quiet enjoyment, and non-infringement of third
                                party rights.
                                American Express Saudi Arabia does not warrant against interference with your enjoyment of
                                the American
                                Express Saudi Arabia Smart phone app, that the functions contained in, or services performed
                                or provided by,
                                the American Express Saudi Arabia Smart phone app will meet your requirements, that the
                                operation of the
                                American Express Saudi Arabia Smart phone app or services will be uninterrupted or
                                error-free, or that
                                defects in the American Express Saudi Arabia Smart phone app or services will be corrected.
                                No oral or
                                written information or advice given by provider or its authorized representative shall
                                create a warranty.
                                Should the American Express Saudi Arabia Smart phone app or services prove defective, you
                                assume the entire
                                cost of all necessary servicing, repair or correction. <br>
                                Access and use of the American Express Saudi Arabia Smart phone app and services occurs
                                across the networks
                                of a number of wireless service providers. American Express Saudi Arabia does not operate
                                these networks and
                                has no control over the operations of the wireless service providers. We will not be liable
                                to you if
                                circumstances beyond our control interrupt, prevent or otherwise affect the transmission,
                                communication,
                                post, transaction or otherwise interfere with the integrity thereof, including, without
                                limitation,
                                unavailability of wireless service, communications, network delays, limitations on wireless
                                coverage, system
                                outages, or interruption of a wireless connection. American Express Saudi Arabia disclaims
                                any
                                responsibility for any wireless service used to access the services. <br>
                                Use of the American Express Saudi Arabia services wirelessly may involve the electronic
                                transmission of
                                personal financial information across the networks of wireless service providers. Because we
                                do not operate
                                or control the wireless networks used to access American Express Saudi Arabia services, we
                                cannot guarantee
                                the privacy or security of wireless data transmissions. Additionally, the wireless device
                                browser is
                                generally pre-configured by your wireless internet service provider. Check with your service
                                provider for
                                information about their privacy and security practices. For personal or confidential
                                information sent to or
                                from American Express Saudi Arabia over the internet from a mobile phone, we require that a
                                "secure session"
                                be established using Transportation Layer Security (“TLS”). American Express Saudi Arabia
                                will treat your
                                data in accordance with the American Express Saudi Arabia privacy statement. American
                                Express Saudi Arabia
                                does not charge for use or access of the American Express Saudi Arabia Smart phone app or
                                any American
                                Express Saudi Arabia services available as of the date of you agreed to this American
                                Express Saudi Arabia
                                services agreement. However, your wireless service provider may levy fees or charges for
                                transmission or
                                receipt of messages and other communications performed using your equipment on the wireless
                                service provider
                                network, and you are solely responsible for such charges. American Express Saudi Arabia
                                provides no warranty
                                that any Smart phone hardware and software will not be damaged by the American Express Saudi
                                Arabia Smart
                                phone app or any data you download using the American Express Saudi Arabia Smart phone app.
                                You expressly
                                acknowledge and agree that use of the American Express Saudi Arabia Smart phone app is at
                                your sole risk and
                                you are responsible for installation and use of the American Express Saudi Arabia Smart
                                phone app. The
                                American Express Saudi Arabia Smart phone app is not fault-tolerant and is not designed,
                                manufactured or
                                intended for use in environments or in operations requiring fail-safe performance.
                            </li> <br>
                            <li>
                                Limitation of liability. To the extent not prohibited by law, in no event shall American
                                Express Saudi
                                Arabia, its direct or indirect subsidiaries, affiliates, agents, employees or
                                representatives be liable for
                                death, personal injury, property damage, or any incidental, special, indirect, punitive,
                                exemplary or
                                consequential damages whatsoever, or for damages for loss of profits, loss of or damage to
                                data, business
                                interruption or any other commercial damages, lost revenues, or other losses of any kind,
                                arising out of
                                this agreement or in any way related to your use or inability to use the American Express
                                Saudi Arabia Smart
                                phone app, however caused, regardless of the theory of liability (contract, tort or
                                otherwise) and even if
                                American Express Saudi Arabia has been advised of the possibility of such damages.
                            </li> <br>
                            <li>
                                Lawful use.  You may not use or otherwise export or re-export the American Express Saudi
                                Arabia Smart Phone
                                App except as authorized the Kingdome of Saudi Arabia law and  the laws of the jurisdiction
                                in which the
                                American Express Saudi Arabia Smart Phone App was obtained.. You also agree that you will
                                not use these
                                products for any purposes prohibited by the Kingdome of Saudi Arabia law.
                            </li> <br>
                            <li>
                                Governing Law, Jurisdiction. The American Express Saudi Arabia Services Agreements and your
                                use of the
                                American Express Saudi Arabia Smart Phone App and any American Express Saudi Arabia Services
                                are governed
                                by, construed and enforced in accordance with the laws of the jurisdiction set forth in the
                                Terms of Service
                                (Our Website Rules and Regulations) (the “Designated Jurisdiction”) which are accessible via
                                the link
                                provided in the American Express Saudi Arabia Smart Phone App. If for any reason a matter is
                                not arbitrated
                                as provided in the American Express Saudi Arabia Services Agreements, then any cause of
                                action brought to
                                enforce the American Express Saudi Arabia Services Agreements, or in connection with any
                                matters related to
                                the American Express Saudi Arabia Smart Phone App or American Express Saudi Arabia Service
                                shall be brought
                                only in the applicable courts located in the Designated Jurisdiction, and you expressly
                                consent to the
                                personal jurisdiction and venue of said courts. Your use of the American Express Saudi
                                Arabia Smart Phone
                                App may also be subject to other local, state, national, or international laws.
                            </li> <br>
                            <li>
                                Severability. If any part of this Agreement is held invalid or unenforceable, the other
                                parts will remain
                                valid.
                            </li> <br>
                            <li>
                                Need Assistance? For assistance with the American Express Saudi Arabia Smart Phone App, call
                                the number on
                                the back of your Card and ask to be connected to American Express Saudi Arabia Online
                                Services
                            </li>
                        </ol>
                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>
    </div>
</template>
  
<script>
import
{
    mdiClose
} from '@mdi/js'
export default {
    props: {
        modal: {
            default: false,
            type: Boolean
        },
    },
    data: () => ({
        icons: { mdiClose }
    }),

    mounted()
    {
        window.scrollTo(0, 0);
    },

    methods: {
        dialogOpen()
        {
            this.$emit('close');
            return this.modal = !this.modal;
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';
</style>
  